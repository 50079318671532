@import 'variables';
@import 'node_modules/react-day-picker/dist/style.css';
/* purgecss start ignore */

.date-picker-range {
	position: absolute;
	top: 2px;
	background-color: #fff;
	border-radius: 4px;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.25);
}

.date-picker .selected {
	text-transform: capitalize;
}

.Selectable .rdp-day_selected:not(.rdp-day_start):not(.rdp-day_end):not(.rdp-day_outside) {
	background-color: $main;
	color: #f0f8ff;
}

.rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
	background-color: $main !important;
	color: #f0f8ff !important;
}

.rdp-day {
	font-size: 15px;
	font-weight: 700;
	color: $black;
	margin: 2px 0px;
	display: table-cell;
	padding: 5px 0 !important;
	border-radius: 50%;
	vertical-align: middle;
	text-align: center;
	cursor: pointer;
	flex: 1;
}

.Selectable .rdp-day_start {
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}

.Selectable .rdp-day_end {
	border-top-right-radius: 50% !important;
	border-bottom-right-radius: 50% !important;
}

.DayPicker {
	display: inline-block;
	font-size: 1rem;
}

.DayPicker-wrapper {
	position: relative;
	flex-direction: row;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.rdp {
	--rdp-cell-size: 37px !important;
	--rdp-background-color: #f0f8ff !important;
	--rdp-accent-color: #{$main} !important;
	margin: 8px !important;
	font-family: var(--font-assistant, sans-serif);
}

.rdp-months {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.rdp-month {
	display: table;
	margin: 0 0;
	border-spacing: 0;
	border-collapse: collapse;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.rdp-nav_button {
	position: absolute !important;
	left: auto;
	display: inline-block;
	background-position: center;
	color: #8b9898 !important;
	cursor: pointer !important;
	transition: all 0.3s;
	top: 50%;
	transform: translateY(-50%);

	&:hover {
		opacity: 0.8;
		background-color: transparent !important;
	}
}

.rdp-nav_button_previous {
	left: 5px;
	right: 0;
}

.rdp-nav_button_next {
	top: 50%;
	transform: translateY(-50%);
	background-size: cover !important;
	right: 5px !important;
}

.rdp-nav_button--interactionDisabled {
	display: none;
}

.rdp-caption {
	display: flex;
	text-align: left;
	border-bottom: solid 1px #cccccc;
	padding: 10px 20px !important;
	margin-bottom: 8px;
	position: relative;
	justify-content: center !important;
}

.rdp-caption_label {
	text-align: center;
	font-family: HelveticaNeue, sans-serif !important;
	text-transform: capitalize;
	font-size: 18px !important;
	font-weight: 700 !important;
	font-style: normal;
	font-stretch: normal;
	line-height: 28px;
	letter-spacing: -0.3px;
	color: $new;
}

.DayPicker-Weekdays {
	display: flex;
}

.DayPicker-WeekdaysRow {
	display: table-row;
}

.DayPicker-Weekday {
	display: table-cell;
	padding: 0.75em;
	color: #8b9898;
	text-align: center;
	font-size: 0.875em;
}

.DayPicker-Weekday abbr[title] {
	border-bottom: none;
	text-decoration: none;
	font-size: 14px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: -0.24px;
	text-align: center;
	color: $black;
}

.rdp-body {
	display: table-row-group;
}

.DayPicker-Week {
	display: flex;
	cursor: default;
	margin: 2px 3px;
}

.rdp-weeknumber {
	display: table-cell;
	padding: 0.5em;
	min-width: 1em;
	border-right: 1px solid #eaecec;
	color: #8b9898;
	vertical-align: middle;
	text-align: right;
	font-size: 0.75em;
	cursor: pointer;
}

.rdp-head_cell {
	text-transform: capitalize !important;
	padding: 0.75em;
}

.DayPicker--interactionDisabled .rdp-day {
	cursor: default;
}

.DayPicker-Footer {
	padding-top: 0.5em;
}

.DayPicker-TodayButton {
	border: none;
	background-color: transparent;
	background-image: none;
	box-shadow: none;
	color: #4a90e2;
	font-size: 0.875em;
	cursor: pointer;
}

/* Default modifiers */

.rdp-day--today {
	font-weight: 700;
}

.rdp-day_outside {
	color: #8b9898;
	cursor: pointer;

	&:hover {
		color: #454545;
	}
	&.rdp-day_selected {
		opacity: 1 !important;
		color: #f6f6f6;
	}
}

.rdp-day--disabled {
	color: #dce0e0;
	cursor: default !important;
	pointer-events: none;
	/* background-color: #eff1f1; */
}

.rdp-day_selected:not(.rdp-day--disabled):hover {
	background-color: #51a0fa;
}

.rdp:not(.DayPicker--interactionDisabled) .rdp-day:not(.rdp-day--disabled):not(.rdp-day_selected):hover {
	background-color: #f0f8ff;
}

.rdp-nav_button[disabled] {
	display: none;
}
/* purgecss end ignore */
@media (max-width: 360px) {
	.date-picker-range {
		width: calc(100vw - 48px);
	}
}
@media (max-width: 321px) {
	.date-picker-range {
		width: 100vw;
		right: 50% !important;
		transform: translateX(50%);
	}
}
