$midnight: #00152c;
$main: #049dd9;
$fontFamily: 'Montserrat', sans-serif;
$navLinkColor: rgba(255, 255, 255, 0.8);
$yellow: #f2c94c;
$black: #333333;
$brownGrey: #9b9b9b;
$aquaBlue: #00d6e6;
$blue: #049dd9;
$blueLight: #09caed;
$new: #011b3f;
